var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-basic-info-edit" },
    [
      _c("base-headline", {
        staticClass: "topTitle",
        attrs: { title: "基本信息" }
      }),
      _c(
        "el-form",
        {
          key: "addForm",
          ref: "addForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "单位名称：",
                    prop: "employerName"
                  }
                },
                [
                  _c("unit-select", {
                    ref: "unitSelectRef",
                    staticClass: "uni-sel",
                    attrs: {
                      isSms4: true,
                      width: "300px",
                      placeholderText: "请输入5个字符搜索",
                      isPost: true,
                      requestUrl: _vm.requestUrl,
                      otherRequestUrl:
                        "/data/exchange/getUnitListFromEnterprise-0",
                      ifShowMoreBtn: true,
                      ifNeedMore: true
                    },
                    on: {
                      unitNameSearch: _vm.unitNameSearch,
                      unitNameSearchOrDel: _vm.unitNameDel,
                      change: _vm.unitNameSelect,
                      loading: data => _vm.$emit("loading", data)
                    },
                    model: {
                      value: _vm.addForm.employerName,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "employerName", $$v)
                      },
                      expression: "addForm.employerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "社会信用代码：",
                    prop: "creditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入社会信用代码",
                      clearable: "",
                      disabled: _vm.creditCodeDisabled
                    },
                    model: {
                      value: _vm.addForm.creditCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "creditCode", $$v)
                      },
                      expression: "addForm.creditCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "行政区划：",
                    prop: "bizZone"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "zoneCodeArea",
                    staticClass: "select-component",
                    attrs: {
                      placeholder: "请选择",
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true
                    },
                    on: { change: _vm.nativePlaceChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "检测类型：",
                    prop: "detectionType"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "260px !important" },
                      model: {
                        value: _vm.addForm.detectionType,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "detectionType", $$v)
                        },
                        expression: "addForm.detectionType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("定期检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 3 } },
                        [_vm._v("监督检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 2 } },
                        [_vm._v("现状评价")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告日期：",
                    prop: "reportDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "",
                      "picker-options": _vm.$validate.noOverToday()
                    },
                    model: {
                      value: _vm.addForm.reportDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reportDate", $$v)
                      },
                      expression: "addForm.reportDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告编号：",
                    prop: "reportCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入报告编号",
                      clearable: "",
                      maxlength: "100"
                    },
                    model: {
                      value: _vm.addForm.reportCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reportCode", $$v)
                      },
                      expression: "addForm.reportCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c("attachmen-upload", {
                attrs: {
                  labelwidth: "110px",
                  folder: "/workplace_detection",
                  label: "报告附件：",
                  accept: ".pdf,.PDF",
                  limit: 5,
                  fileProp: "annexList1"
                },
                model: {
                  value: _vm.addForm.annexList1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "annexList1", $$v)
                  },
                  expression: "addForm.annexList1"
                }
              })
            ],
            1
          ),
          _c(
            "base-headline",
            { attrs: { title: "主要产品" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: {
                    icon: "el-icon-plus",
                    disabled: _vm.addForm.ifClassifiedUnit
                  },
                  on: { click: _vm.addProduct }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "14px" },
                  model: {
                    value: _vm.addForm.ifClassifiedUnit,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "ifClassifiedUnit", $$v)
                    },
                    expression: "addForm.ifClassifiedUnit"
                  }
                },
                [_vm._v("军工等涉密企业")]
              ),
              _c(
                "div",
                {
                  staticClass: "ms-el-message ms-el-message--warning",
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。 "
                  )
                ]
              )
            ],
            1
          ),
          !_vm.addForm.ifClassifiedUnit
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.addForm.products,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "index", label: "序号", width: "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3056706777
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." +
                                          scope.$index +
                                          ".productName",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请输入产品名称",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        staticStyle: {
                                          width: "200px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "50"
                                        },
                                        model: {
                                          value: scope.row.productName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4002050973
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "年产量",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." +
                                          scope.$index +
                                          ".annualOutput",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请输入年产量",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        staticStyle: {
                                          width: "200px !important"
                                        },
                                        attrs: {
                                          oninput:
                                            "value=value.replace(/[^0-9.]/g, '')",
                                          placeholder: "请输入数字",
                                          maxlength: "12"
                                        },
                                        model: {
                                          value: scope.row.annualOutput,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "annualOutput",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.annualOutput"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          782318301
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "计量单位",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." + scope.$index + ".unit",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请选择单位",
                                            trigger: "change"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-autocomplete", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          "value-key": "label",
                                          "fetch-suggestions":
                                            _vm.unitAutocompleteQuery,
                                          maxlength: 10,
                                          clearable: ""
                                        },
                                        model: {
                                          value: scope.row.unit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "unit",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.unit"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          19344063
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeRow(scope.$index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3328054818
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("base-headline", { attrs: { title: "检测信息" } }),
          _c("div", {}, [_c("HazardDistribution", { ref: "hazard" })], 1)
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save(0)
                }
              }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save(1)
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }