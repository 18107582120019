var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "msForm",
      staticClass: "zwx-form edit-form",
      attrs: {
        model: _vm.msForm,
        "label-position": "right",
        "label-width": "72px"
      }
    },
    [
      _c("base-headline", {
        staticClass: "no-top",
        staticStyle: { "margin-bottom": "7px" },
        attrs: { title: "基本信息" }
      }),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "行政区划：", prop: "busZoneCode" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(
                  _vm._s(
                    _vm.msForm.busZoneAreaExp?.fullName?.replaceAll("_", "/") ||
                      "-"
                  )
                )
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构名称：", prop: "unitName" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.unitName || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "社会信用代码：",
                prop: "creditCode"
              }
            },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.creditCode || "-"))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "机构编码：", prop: "unitCode" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.unitCode || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册日期：", prop: "registerDate" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.registerDate || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "法定代表人：",
                prop: "legalPerson"
              }
            },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.legalPerson || "-"))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "联系人：", prop: "linkPerson" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.linkPerson || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "unitContact" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.desensitiUnitContact || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "培训机构公章：",
                prop: "annexList"
              }
            },
            [
              _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                ? _c(
                    "div",
                    { staticClass: "show-file" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-text-26",
                          on: {
                            click: function($event) {
                              return _vm.openFilePreview1(
                                _vm.msForm.annexList,
                                0
                              )
                            }
                          }
                        },
                        [_vm._v("查看")]
                      )
                    ],
                    1
                  )
                : _c("span", [_vm._v("-")])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-row",
          staticStyle: {
            display: "flex",
            "align-items": "center",
            width: "60%",
            margin: "9px 2px",
            color: "#3A485E !important"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "line-height": "18px",
                "align-self": "flex-start",
                "min-width": "70px"
              }
            },
            [_vm._v("管辖范围：")]
          ),
          !_vm.msForm.areaAndTrainingAgencyRelationExpList.length
            ? _c("div", { staticStyle: { "line-height": "18px" } }, [
                _vm._v("-")
              ])
            : _vm._e(),
          _c("div", { staticStyle: { "line-height": "18px" } }, [
            _vm._v(
              _vm._s(
                _vm.msForm.areaAndTrainingAgencyRelationExpList
                  .map(v => v.busZoneCodeName)
                  .join("，")
              )
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "edit-row",
          staticStyle: { "padding-bottom": "7px !important" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "机构地址：", prop: "busZoneCodeAddr" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.busZoneCodeAddr || "-"))
              ])
            ]
          ),
          _vm.ifTrainingExamSplit
            ? _c(
                "el-form-item",
                { attrs: { label: "营业时间：", prop: "tradeTime" } },
                [
                  _c("span", { staticClass: "form-item-value" }, [
                    _vm._v(_vm._s(_vm.msForm.tradeTime || "-"))
                  ])
                ]
              )
            : _vm._e(),
          _vm.ifTrainingExamSplit
            ? _c(
                "el-form-item",
                { attrs: { label: "考试地址：", prop: "examAddr" } },
                [
                  _c("span", { staticClass: "form-item-value" }, [
                    _vm._v(_vm._s(_vm.msForm.examAddr || "-"))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("base-headline", {
        staticStyle: { "margin-bottom": "7px" },
        attrs: { title: "管理员" }
      }),
      _c(
        "div",
        {
          staticClass: "edit-row",
          staticStyle: { "padding-bottom": "7px !important" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名：", prop: "adminUserInfoExp.userName" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.adminUserInfoExp.userName || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "手机号：", prop: "adminUserInfoExp.mobileTel" }
            },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.adminUserInfoExp.mobileTel || "-"))
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号：", prop: "adminUserInfoExp.userNo" } },
            [
              _c("span", { staticClass: "form-item-value" }, [
                _vm._v(_vm._s(_vm.msForm.adminUserInfoExp.userNo || "-"))
              ])
            ]
          )
        ],
        1
      ),
      _c("app-config-table", {
        ref: "appConfigTable",
        attrs: { config: _vm.config },
        model: {
          value: _vm.msForm.agencyPayBindExpList,
          callback: function($$v) {
            _vm.$set(_vm.msForm, "agencyPayBindExpList", $$v)
          },
          expression: "msForm.agencyPayBindExpList"
        }
      }),
      _c("invoice-config-table", {
        ref: "invoiceConfigTable",
        attrs: { config: _vm.invoiceConfig },
        model: {
          value: _vm.msForm.agencyInvoiceBindExpList,
          callback: function($$v) {
            _vm.$set(_vm.msForm, "agencyInvoiceBindExpList", $$v)
          },
          expression: "msForm.agencyInvoiceBindExpList"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }