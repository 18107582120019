<template>
  <div class="detection-report-basic-info-edit">
    <base-headline title="基本信息" class="topTitle" />
    <el-form class="zwx-form edit-form" ref="addForm" key="addForm" :model="addForm" :rules="rules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="110px" label="单位名称：" prop="employerName">
          <unit-select class="uni-sel" :isSms4="true" ref="unitSelectRef" width="300px" v-model="addForm.employerName" placeholderText="请输入5个字符搜索" :isPost="true" :requestUrl="requestUrl" otherRequestUrl="/data/exchange/getUnitListFromEnterprise-0" :ifShowMoreBtn="true" :ifNeedMore="true" @unitNameSearch="unitNameSearch" @unitNameSearchOrDel="unitNameDel" @change="unitNameSelect" @loading="data => $emit('loading', data)" />
        </el-form-item>
        <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
          <el-input class="zwx-input" style="width:300px !important" v-model="addForm.creditCode" placeholder="请输入社会信用代码" clearable :disabled="creditCodeDisabled" />
        </el-form-item>
      </div>

      <div class="edit-row">
        <el-form-item label-width="110px" label="行政区划：" prop="bizZone">
          <zwx-select-area ref="zoneCodeArea" class="select-component" placeholder="请选择" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" />
        </el-form-item>
        <el-form-item label-width="110px" label="检测类型：" prop="detectionType">
          <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="addForm.detectionType">
            <el-radio class="zwx-radio" :label="1">定期检测</el-radio>
            <el-radio class="zwx-radio" :label="3">监督检测</el-radio>
            <el-radio class="zwx-radio" :label="2">现状评价</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="edit-row">
        <el-form-item label-width="110px" label="报告日期：" prop="reportDate">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:300px !important" v-model="addForm.reportDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="" :picker-options="$validate.noOverToday()" />
        </el-form-item>
        <el-form-item label-width="110px" label="报告编号：" prop="reportCode">
          <el-input class="zwx-input" style="width:300px !important" v-model="addForm.reportCode" placeholder="请输入报告编号" clearable maxlength="100"/>
        </el-form-item>
      </div>

      <div class="edit-row">
        <attachmen-upload labelwidth="110px" folder="/workplace_detection"
        v-model="addForm.annexList1" label="报告附件：" accept=".pdf,.PDF"  :limit="5" fileProp="annexList1" />
        <!-- <el-form-item label-width="110px" label="报告附件：" prop="annexList1">
          <div style="display:flex;">
            <file-upload ref="msPositive1" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".pdf,.PDF" :size="50 * 1024 * 1024" sizeInfo="50M" :limit="5">
              <div style="text-align: left">
                <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">上传</el-button>
              </div>
            </file-upload>
            <div style="display: inline-block;margin-left:20px;" class="show-file" v-if="addForm.annexList1.length > 0">
              <div style="display: flex;align-items: center;" v-for="(item, index) in addForm.annexList1" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                <div class="file-name-hidden">
                  <a style="color:#194DFF !important;margin-left:0px;cursor:pointer;" @click="openFilePreview1(addForm.annexList1, index)">
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    <span :title="item.fileName">{{ item.fileName }}</span>
                  </a>
                </div>
                <div>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                  <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive1(index)"></i></span>
                </div>
              </div>
            </div>
          </div>
        </el-form-item> -->
      </div>
      <base-headline title="主要产品">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addProduct" :disabled="addForm.ifClassifiedUnit">添加</el-button>
        <el-checkbox v-model="addForm.ifClassifiedUnit" style="margin-left: 14px">军工等涉密企业</el-checkbox>
        <div class="ms-el-message ms-el-message--warning" style="display: inline-block;margin-left: 10px;">
          <i class="el-icon-warning"></i>
          非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。
        </div>
      </base-headline>
      <!-- <div class="edit-row">
        <el-form-item label-width="150px" label="军工等涉密产品：" prop="ifClassifiedUnit">
          <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="addForm.ifClassifiedUnit">
            <el-radio class="zwx-radio" :label="false">否</el-radio>
            <el-radio class="zwx-radio" :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <div v-if="!addForm.ifClassifiedUnit">
        <!-- <el-button class="zwx-button zwx-button-icontext-28" style="margin-bottom:8px;" icon="el-icon-plus" @click="addProduct">添加</el-button> -->
        <!-- <el-form :model="products" :rules="productsRules" ref="productform" > -->
        <el-table class="zwx-table" style="width: 100%;" :data="addForm.products" border stripe>
          <el-table-column prop="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品名称" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.productName'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请输入产品名称', trigger: 'blur' }]">
                <el-input v-model="scope.row.productName" class="zwx-input" style="width:200px !important" placeholder="请输入" maxlength="50"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="年产量" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.annualOutput'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请输入年产量', trigger: 'blur' }]">
                <el-input v-model="scope.row.annualOutput" class="zwx-input" style="width:200px !important" oninput="value=value.replace(/[^0-9.]/g, '')" placeholder="请输入数字" maxlength="12"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="计量单位" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.unit'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请选择单位', trigger: 'change' }]">
                <el-autocomplete class="zwx-input" v-model.trim="scope.row.unit" value-key="label" :fetch-suggestions="unitAutocompleteQuery" :maxlength="10" clearable></el-autocomplete>
                <!-- <el-input v-model="scope.row.annualOutput" class="zwx-input" style="width:200px !important" placeholder="请输入数字"></el-input> -->
                <!-- <el-select v-model="scope.row.unit" class="zwx-input" style="width:200px !important">
                  <el-option v-for="item in unitOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select> -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="removeRow(scope.$index)" type="text" style="color: red;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-form> -->
      </div>

      <base-headline title="检测信息" />
      <div class="">
        <HazardDistribution ref="hazard"></HazardDistribution>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save(0)">暂存</el-button>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save(1)">提交</el-button>
    </fixed-footer>
  </div>
</template>

<script>
import HazardFactorMultipleEdit from '@/components/business/HazardFactorMultipleEdit.vue'
import HazardDistribution from './components/HazardDistribution.vue'
import unitSel from './components/unit-sel.vue'
export default {
  name: 'DetectionReportBasicInfoEdit',
  props: {
    rid: { type: String, default: '' },
  },
  components: { HazardFactorMultipleEdit, HazardDistribution, unitSel },
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      requestUrl: '/basic/info/getEmployerInfoByUnitNameLike-1',
      creditCodeDisabled: true,
      zoneDisabled: true,
      unitOptionList: [],
      addForm: {
        rid: this.$route.params.rid,
        detectionType: null,
        reportType: this.$route.params.reportType,
        organName: null,
        reportDate: null,
        reportName: null,
        reportCode: null,
        annexList1: [],
        annexList2: [],
        organCreditCode: null,
        organUid: null,
        dataState: null,
        employerName: '',
        creditCode: '',
        bizZone: '',
        zoneType: '',
        ifClassifiedUnit: false,
        products: [],
      },
      zoneCode12From: '000000000000',
      hazardOrgList: [], //危害因素列表
      hazardList: [], //危害因素层级

      rules: {
        detectionType: [{ required: true, message: '请选择检测类型', trigger: ['change', 'blur'] }],
        reportDate: [{ required: true, message: '请选择报告日期', trigger: ['change', 'blur'] }],
        reportName: [{ required: true, message: '请输入检测报告名称', trigger: ['change', 'blur'] }],
        reportCode: [
          { required: true, message: '请输入报告编号', trigger: ['change', 'blur'] },
          { validator: this.reportCodeIfExist, trigger: 'blur' },
        ],
        ifClassifiedUnit: [{ required: true, trigger: ['change', 'blur'] }],
        annexList1: [{ required: true, message: '请上传报告附件', trigger: ['change', 'blur'] }],
        employerName: [
          { required: true, message: '请输入单位名称', trigger: ['blur'] },
          { validator: this.validateUnitName, trigger: ['blur', 'change'] },
        ],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['blur'] },
          { validator: this.validateCreditCode, trigger: ['blur'] },
        ],
        bizZone: [
          { required: true, message: '请选择行政区划', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        // productName: [
        //   { required:!addForm.ifClassifiedUnit, message: '请输入产品名称', trigger: 'blur' }
        // ],
        // annualOutput: [
        //   { required: !addForm.ifClassifiedUnit, message: '请输入年产量', trigger: 'blur' },
        //   { type: 'number', message: '年产量必须是数字', trigger: 'blur' }
        // ],
      },
    }
  },
  watch:{
    'addForm.annexList1':{
        handler(newVal, oldVal) {
            this.$nextTick(()=>{
                this.$refs.addForm.validateField('annexList1')
            this.$forceUpdate()
            })
        },
        deep: true,
        },
      'addForm.ifClassifiedUnit':{
        handle(newVal,oldVal){
          if(newVal){
          this.addForm.products=[]
          }
        },
        deep: true,
      }
    },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  created() {
    this.$system.get(
      this.api + '/systematic/getParameterListByParameterKeys-0',
      { parameterKeys: 'SYSTEM_ZONE_CODE' },
      false,
      true,
      data => {
        if (data.type === '00' && data.parameterList.length > 0) {
          this.zoneCode12From = data.parameterList[0].parameterValue
          this.$nextTick(()=>{
            this.$refs.zoneCodeArea.initSearch()   
          })
          
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$notify.error({ title: '错误', message: data.mess })
        }
      },
      this.error
    )
  },
  mounted() {
    // this.$refs.zoneCodeArea.initSearch()
    // if (this.$zwxBase.verifyIsNotBlank(this.$route.params.employerUuid)) {
    //   this.employerUuid = this.$route.params.employerUuid
    // }
    if (!this.$zwxBase.verifyIsBlank(this.$route.params.uuid)) {
      this.queryDetectionReportBasicInfo(this.$route.params.uuid)
    }
    this.getUnits()
    this.msHazardsSelectSearch()
  },
  methods: {
    getUnits() {
      const data = {
        codeTypeCodes: '2002',
      }
      this.$system.get(
        this.api + '/systematic/getSimpleCodeListByCodeTypeCodesMap-0',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.unitOptionList = data.resultMap[2002].map(i => {
              return {
                label: i.codeName,
                value: i.codeName,
              }
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    unitAutocompleteQuery: function(queryString, cb) {
      var unitSimpleCodeList = this.unitOptionList
      var results = queryString ? unitSimpleCodeList.filter(this.unitAutocompleteFilter(queryString)) : unitSimpleCodeList
      cb(results)
    },
        /**
     * 计量单位过滤器
     */
     unitAutocompleteFilter: function(queryString) {
      return function(obj) {
        return obj.label.indexOf(queryString) === 0
      }
    },
    //危害因素查询
    msHazardsSelectSearch: function() {
      let data = {}
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/basic/info/getHazardsStandardExpList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.hazardOrgList = data.dataList
            this.hazardList = this.manyListFul(data.dataList, '')
            console.log(hazardList)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.levelCodeNo.split('.')
        if ((e.levelCodeNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    addProduct() {
      this.addForm.products.push({ productName: '', annualOutput: '', unit: '' })
    },
    removeRow(index) {
      this.addForm.products.splice(index, 1)
    },
    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(this.$refs.zoneCodeArea.getChooseArea())) {
        callback(new Error('请选择'))
      }
      let zoneType = this.$refs.zoneCodeArea.getChooseArea().zoneType
      let childNum = this.$refs.zoneCodeArea.getChooseArea().childNum
      if (this.$zwxBase.verifyIsNotBlank(zoneType) && this.$zwxBase.verifyIsNotBlank(childNum)) {
        if (childNum != 0) {
          callback()
        } else {
          if (zoneType > 4) {
            callback(new Error('请选择到街道级'))
          }
        }
      }
      callback()
    },
    //行政区划
    nativePlaceChange() {
      if (this.$refs.zoneCodeArea.getChooseArea()) {
        this.addForm.zoneType = this.$refs.zoneCodeArea.getChooseArea().zoneType
        this.addForm.bizZone = this.$refs.zoneCodeArea.getChooseArea() == null ? null : this.$refs.zoneCodeArea.getChooseArea().zoneCode12
      }
    },
    /**
     * 单位名称长度校验
     */
    validateUnitName(rule, value, callback) {
      if (value != undefined && value != null && value != '' && value.length < 5) {
        callback(new Error('至少输入5个字符进行检索'))
      }
      callback()
    },

    //机构未搜索到数据
    unitNameSearch() {
      this.creditCodeDisabled = true
      this.zoneDisabled = true
      this.$refs.unitSelectRef.unitNameSearchVisible = false
      this.$system.notify('提示', '未检索到单位信息', 'info')
    },
    //机构清空回调方法
    unitNameDel() {
      this.zoneCode12From = '320000000000'
      this.$refs.zoneCodeArea.initSearch()
      this.creditCodeDisabled = true
      // this.zoneDisabled = true
      this.searchUnitList = []
      this.addForm.creditCode = ''
      this.addForm.employerName = ''
      this.addForm.zoneType = ''
      this.zoneDisabled = false
      //天眼查组件清空赋值------开始
      this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-search'
      this.$refs.unitSelectRef.unitNameDisabled = false
      //给天眼查组件清空赋值------结束
      this.$nextTick(() => {
        this.$refs['addForm'].clearValidate()
      })
    },
    // 单位信息选择回调方法
    unitNameSelect(item) {
      //客户信息
      this.addForm.employerName = item.unitName
      this.addForm.creditCode = item.creditCode
      this.creditCodeDisabled = true
      this.employerUuid = item.unitUid || ''
      this.addForm.bizZone = item.bizZone || ''
      this.zoneDisabled = item.bizZone != undefined
      if (item.ifClassifiedUnit === false) {
        this.addForm.ifClassifiedUnit = false
        if(item.unitUid){
          this.getProduct(item.unitUid)
        }
      }else{
        if(item.ifClassifiedUnit){
          this.addForm.ifClassifiedUnit =item.ifClassifiedUnit
        }
        
        this.addForm.products =[]
      }
      if (item.bizZone) {
        this.zoneCode12From = '000000000000'
      } else {
        this.zoneCode12From = '440000000000'
      }
      this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)
    },
    getProduct(uid) {
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/basic/info/getEmployerProductByUnitUid-1',
        { mainUid: uid },
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.addForm.products = data.productExpList
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    queryDetectionReportBasicInfo(rid) {
      let data = {
        uuid: rid,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/workplace/detection/getDetectionReport-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.addForm.uuid = data.resultInfo.uuid
            this.addForm.detectionType = data.resultInfo.detectionType
            this.addForm.organName = data.resultInfo.organName
            this.addForm.reportDate = data.resultInfo.reportDate
            this.addForm.reportName = data.resultInfo.reportName
            this.addForm.reportCode = data.resultInfo.reportCode
            this.addForm.employerName = data.resultInfo.employerName
            this.addForm.bizZone = data.resultInfo.bizZone
            this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)
            this.addForm.creditCode = data.resultInfo.employerCreditCode
            //给天眼查组件赋值后置灰------开始
            this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-delete'
            this.$refs.unitSelectRef.unitNameDisabled = true
            // 给天眼查组件赋值后置灰------结束
            // this.addForm.organCreditCode = data.resultInfo.organCreditCode
            // this.addForm.organUid = data.resultInfo.organUid
            this.addForm.annexList1 = []
            if(data.resultInfo.reportAnnexExpList1.length){
              this.addForm.annexList1.push({
              fileName: data.resultInfo.reportAnnexExpList1[0].fileName,
              filePath: data.resultInfo.reportAnnexExpList1[0].filePath,
              check: false,
              fileIconShow: 'colorful chis-icon-colorful-pdf',
            })
            }
            setTimeout(()=>{
              this.$nextTick(()=>{
                this.$refs['addForm'].clearValidate()
              })
            },0)
            this.addForm.ifClassifiedUnit = data.resultInfo.ifClassifiedUnit
            this.addForm.products = data.resultInfo.detectionProductExpList
            this.$refs.hazard.setList(data.resultInfo)
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    clickPositive(showList) {
      showList.forEach(item => {
        let flag = this.addForm.annexList1.some(e => e.fileName == item)
        if (flag) {
          this.$refs.msPositive1.deleteIndex(showList.length - 1)
          return
        }
        this.addForm.annexList1.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid1()
      if (this.addForm.annexList1 != null && this.addForm.annexList1 != undefined && this.addForm.annexList1.length > 0) {
        this.$refs.addForm.clearValidate('annexList1')
      }
    },
    clickPositive2(showList) {
      showList.forEach(item => {
        let flag = this.addForm.annexList2.some(e => e.fileName == item)
        if (flag) {
          this.$refs.msPositive2.deleteIndex(showList.length - 1)
          return
        }
        this.addForm.annexList2.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid2()
      if (this.addForm.annexList2 != null && this.addForm.annexList2 != undefined && this.addForm.annexList2.length > 0) {
        this.$refs.addForm.clearValidate('annexList2')
      }
    },
    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
    clickPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList1) {
          for (let j in fileList) {
            if (this.addForm.annexList1[i].fileName === fileList[j].fileName) {
              this.addForm.annexList1[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    clickPositiveSuccess2(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList2) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList2) {
          for (let j in fileList) {
            if (this.addForm.annexList2[i].fileName === fileList[j].fileName) {
              this.addForm.annexList2[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    clickError() {},
    mouseenter(index) {
      this.addForm.annexList1[index].check = true
    },
    mouseleave(index) {
      this.addForm.annexList1[index].check = false
    },
    mouseenter2(index) {
      this.addForm.annexList2[index].check = true
    },
    mouseleave2(index) {
      this.addForm.annexList2[index].check = false
    },
    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    // 删除检测报告协议
    deletionPositive1(index) {
      let fileName = this.addForm.annexList1[index].fileName
      this.addForm.annexList1.splice(index, 1)
      this.$refs.msPositive1.deleteIndex(index)
      let fileList = this.$refs.msPositive1.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive1.deleteIndex(j)
            fileList = this.$refs.msPositive1.showFileList || []
            continue
          }
        }
      }
    },
    // 删除检测报告协议
    deletionPositive2(index) {
      let fileName = this.addForm.annexList2[index].fileName
      this.addForm.annexList2.splice(index, 1)
      this.$refs.msPositive2.deleteIndex(index)
      let fileList = this.$refs.msPositive2.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive2.deleteIndex(j)
            fileList = this.$refs.msPositive2.showFileList || []
            continue
          }
        }
      }
    },
    peOrganSelectChange(value, row) {
      this.$set(row, 'organUid', value.unitUid)
      this.$set(row, 'organName', value.organName)
      this.$set(row, 'organCreditCode', value.creditCode)
    },
    checkData(dataState){
      let { existsBiotic, existsChemical, existsDust, existsOther, existsPhysical, existsRadioactivity, dustContacts, chemicalContacts, bioticContacts, physicalContacts, radioactivityContacts, otherContacts, bioticHazardList, chemicalHazardList, dustHazardList, otherHazardList, physicalHazardList, radioactivityHazardList } = this.$refs.hazard.msForm
              var simpleHazardsExpList = []
              bioticHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 5,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })
              chemicalHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 2,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })
              dustHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 1,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })
              otherHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 6,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })
              physicalHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 3,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })
              radioactivityHazardList.map(item => {
                item.map(i => {
                  simpleHazardsExpList.push({
                    hazardsSort: 4,
                    hazardsCode: i.hazardsCode,
                    hazardsName: i.hazardsName,
                    staffNumber: i.staffNumber,
                    checkNumber: i.checkNumber,
                    overNumber: i.overNumber,
                  })
                })
              })

              var data = {
                dataState: dataState,
                employerName: this.addForm.employerName,
                employerCreditCode: this.addForm.creditCode,
                bizZone: this.addForm.bizZone,
                detectionType: this.addForm.detectionType,
                reportDate: this.addForm.reportDate,
                ifClassifiedUnit: this.addForm.ifClassifiedUnit,
                reportCode:this.addForm.reportCode,
                detectionProductExpList: this.addForm.products.map(i => {
                  return {
                    productName: i.productName,
                    annualOutput: i.annualOutput,
                    unit: i.unit,
                  }
                }),
                simpleDistributionExp: { existsBiotic, existsChemical, existsDust, existsOther, existsPhysical, existsRadioactivity, dustContacts, chemicalContacts, bioticContacts, physicalContacts, radioactivityContacts, otherContacts },
                reportAnnexExpList1: this.addForm.annexList1,
                simpleHazardsExpList,
              }
              this.submitData(data)
    },
    save(dataState) {
      if (dataState == 0) {
        this.$refs['addForm'].validateField('employerName', error => {
          if (!error) {
            this.$refs['addForm'].validateField('detectionType', error => {
              if (!error) {
                this.checkData(dataState)
              }
            })
          }
        })
      } else {
      
        this.$refs['addForm'].validate(valid => {
          console.log(valid)
          if (this.addForm.products.length == 0 && this.addForm.ifClassifiedUnit === false) {
            this.$message.error('需要填写产品信息')
          }

          if (valid) {
            this.$refs.hazard.saveAll(() => {
              this.checkData(dataState)
           
            })
          }
        })
      }
      // this.$refs.productform.validate(valid => {
      //   if (valid) {
      //     console.log(this.products)
      //     this.$message.success('提交成功');
      //   } else {
      //     this.$message.error('请检查表单内容');
      //   }
      // });
      // this.addForm.dataState = dataState
    },
    submitData(data) {
      if (this.addForm.uuid) {
        data.uuid = this.addForm.uuid
      }
      var postData=data
      this.$system.postJson(
        this.api + '/workplace/detection/addOrModifyDetectionReport1-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            if(postData.dataState==0){
              this.$system.notify('成功', data.mess, 'success')
              this.addForm.uuid=data.uuid
            }else{
              this.$system.notify('成功', data.mess, 'success')
              this.$router.push({ name: 'DetectionReportManagement',  })
            }
            //  this.$refs.msPositive1.submit(data.annexUploadUid)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        {}
      )
    },
    // 上传检测报告 文件的UID
    generateFilesUid1() {
      this.$emit('loading', true)
      let data = {
        employerId: this.employerBasicId,
        folder: '/workplace_detection',
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-1', data, true, true, this.generateFilesUidSuccess1, {})
    },
    generateFilesUidSuccess1(data) {
      if (data.type === '00') {
        this.$refs.msPositive1.submit(data.annexUploadUid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    generateFilesUid2() {
      this.$emit('loading', true)
      let data = {
        employerId: this.employerBasicId,
        folder: '/workplace_detection',
      }
      this.$system.post(this.api + '/zky/employer/generateFilesUid-1', data, true, true, this.generateFilesUidSuccess2, {})
    },
    generateFilesUidSuccess2(data) {
      if (data.type === '00') {
        this.$refs.msPositive2.submit(data.uid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    submit() {
      let data = {
        rid: this.addForm.rid,
        reportType: this.addForm.reportType,
        dataState: this.addForm.dataState,
        detectionType: this.addForm.detectionType,
        organName: this.addForm.organName,
        reportDate: this.addForm.reportDate,
        reportName: this.addForm.reportName,
        reportCode: this.addForm.reportCode,
        reportAnnexExpList1: this.addForm.annexList1,
        reportAnnexExpList2: this.addForm.annexList2,
        organCreditCode: this.addForm.organCreditCode,
        organUid: this.addForm.organUid,
        employerUuid: this.employerUuid,
        employerCreditCode: this.addForm.creditCode,
        employerName: this.addForm.employerName,
        bizZone: this.addForm.bizZone,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/workplace/detection/addOrModifyTdDetectionReportFromOrgan-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', '提交成功', 'success')
            this.back()
            //this.$refs.detectionReportRef.show(false)
            //this.queryInquire(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //报告编号查重
    reportCodeIfExist(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        let data = {
          uuid:this.addForm.uuid,
          reportCode: this.addForm.reportCode, //检测报告编码
        }
        this.$system.postJson(this.api + '/workplace/detection/getReportCodeIfExist-1', data, true, true, data => {
          if (data.type === '00') {
            if (data.ifExist) {
              return callback(new Error('报告编号重复'))
            } else {
              return callback()
            }
          } else {
            return callback(new Error(data.mess))
          }
        })
      }
    },
    back() {
      this.$router.replace({ name: this.breadcrumbs[this.breadcrumbs.length - 2].name })
    },
  },
}
</script>

<style lang="less" scoped>
.topTitle {
  /deep/ .zwx-head-title {
    border-top: none !important;
  }
}
/deep/.detection-report-dialog .el-dialog {
  width: 680px !important;
  min-width: 680px !important;
}
.detection-report-basic-info-edit {
  .select-component {
    /deep/ .zwx-input {
      width: 300px !important;
      cursor: pointer;
    }

    /deep/ .el-input__suffix {
      right: 6px !important;
    }

    /deep/ .el-input__inner {
      width: 300px;
    }
    /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
      margin-top: 0;
      line-height: 32px !important;
    }
  }
}
.uni-sel {
  /deep/ .el-icon-search {
    margin-top: -10px !important;
  }
  /deep/ .el-icon-delete {
    margin-top: -10px !important;
  }
}
.ms-el-message {
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 236px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  padding: 0px 10px 0px 9px;
  border-radius: 2px;
}

.ms-el-message--warning {
  background-color: #fff5e6;
  border-color: #ffa738;
  color: #ff9400;
}
</style>
<style lang="less">
.file-name-hidden {
  display: inline-block;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
