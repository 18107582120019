<template>
    <!-- 接触危害因素类别组件 -->
    <div class="hazard-factor-multiple-edit-index" :style="popoverSelectStyle">
        <el-popover class="ms-popover-select" popper-class="hazard-factor-multiple-popover" v-model="popoverVisible"
                    @after-enter="popoverShow" @after-leave="popoverHide" placement="bottom-start" trigger="click"
                    :disabled="disabled">
            <div :style="popoverSelectStyle">
                <div class="ms-popover-select-search">
                    <el-input class="zwx-input" :ref="searchRef" v-model="searchInput"
                              :placeholder="searchPlaceholder"></el-input>
                </div>
                <div class="ms-popover-select-content">
                    <div class="ms-popover-select-content-left">
                        <zwx-scrollbar-y style="height: 337px;" :ref="treeScrollbarRef">
                            <el-tree class="ms-tree" :ref="treeRef" :data="showDataList" @check-change="treeCheckChange"
                                     :filter-node-method="filterNodeMethod" node-key="rid" children="subList"
                                     show-checkbox accordion></el-tree>
                        </zwx-scrollbar-y>
                    </div>
                    <div class="ms-popover-select-content-right">
                        <div class="ms-popover-select-content-right-check">{{selectContentHeadFiled}}（{{ showCheckDataList.length }}）</div>
                        <zwx-scrollbar-y style="height: 300px;padding: 10px 0" :ref="checkScrollbarRef">
                            <div class="ms-popover-select-content-right-item" v-for="(item, index) in showCheckDataList"
                                 :key="index">
                                <div style="width: 100%;height: 100%;display: flex;align-items: center"
                                     @click="changeHzardName(item, index)">
                                    <span v-show="editCode !== item[dataCodeFiled]">{{ item[showNameFiled] }}</span>
                                    <el-input class="zwx-input" @blur="focusHazardInput(item)" :ref="item[dataCodeFiled]+index"
                                              v-show="ifEdit && editCode === item[dataCodeFiled]"
                                              style="width: 100% !important" v-model.trim="item[showNameFiled]"
                                              placeholder="请输入"/>
                                </div>
                                <div class="icon-delete-class">
                                    <i style="cursor: pointer;" class="el-icon-delete"
                                       @click="showCheckDataListDelete(index)"></i>
                                </div>
                            </div>
                        </zwx-scrollbar-y>
                    </div>
                </div>
            </div>
            <el-input class="zwx-input" :style="popoverSelectStyle" slot="reference" v-model="input"
                      :placeholder="placeholder" readonly :disabled="disabled">
                <i :class="popoverVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                   slot="suffix" icon="el-icon-arrow-down" style="padding-right: 5px"></i>
            </el-input>
        </el-popover>
    </div>
</template>

<script>
    export default {
        name: 'hazardFactorMultipleEdit',
        props: {
            innerWidth: {type: Number, required: false, default: 160},
            placeholder: {type: String, required: false, default: ''},
            searchPlaceholder: {type: String, required: false, default: '关键词检索'},
            clearable: {type: Boolean, required: false, default: true},
            disabled: {type: Boolean, required: false, default: false},
            ifJustLeaf: {type: Boolean, required: false, default: true},
            dataPyxNameFiled: {type: String, required: false, default: ''},
            dataPinyinCodeFiled: {type: String, required: false, default: 'pinyinCode'},
            dataNameFiled: {type: String, required: true},
            showNameFiled: {type: String, required: true},
            selectContentHeadFiled: {type: String, default: '已选危害因素'},
            dataCodeFiled: {type: String, required: true},
            dataUpCodeFiled: {type: String, required: true},
            ifEdit: {type: Boolean, required: false, default: true},
            options:{type: Array, required: true}
        },
        data() {
            return {
                api: this.$store.state.api,
                searchRef: 'input_' + this.$system.uuid(),
                treeScrollbarRef: 'tree_' + this.$system.uuid(),
                treeRef: 'tree_' + this.$system.uuid(),
                checkScrollbarRef: 'check_' + this.$system.uuid(),
                popoverVisible: false,
                input: '',
                searchInput: '',
                dataList: [],
                showDataList: [],
                checkDataList: [],
                showCheckDataList: [],
                echoCheckDataCodeList: [],
                excludeCodeList: [],
                codeNameMap:null,
                editCode: '',
            }
        },
        created() {
        },
        mounted() {
            this.setLinearDataList(this.options)
        },
        computed: {
            skin() {
                return this.$store.state.skin
            },
            popoverSelectStyle() {
                return `width: ${this.innerWidth}px !important;`
            },
        },
        watch: {
            searchInput() {
                if (this.popoverVisible) this.search(this.searchInput)
            },
        },
        methods: {
            // 线形转树形第一级
            linearDataListTreeFisrt(dataList) {
                this.showDataList.length = 0
                for (let i = 0, length = dataList.length; i < length; i++) {
                    const data = dataList[i]
                    if (data[this.dataUpCodeFiled] !== undefined && data[this.dataUpCodeFiled] !== null && data[this.dataUpCodeFiled] !== '' && data[this.dataUpCodeFiled] !== data[this.dataCodeFiled]) continue
                    this.showDataList.push(dataList[i])
                    dataList.splice(i, 1)
                    length--
                    i--
                }
            },
            // 线形转树形递归
            linearDataListTreeRecursion(showDataList, dataList) {
                for (let i = 0, length = showDataList.length; i < length; i++) {
                    for (let j = 0, jLength = dataList.length; j < jLength; j++) {
                        if (showDataList[i][this.dataCodeFiled] !== dataList[j][this.dataUpCodeFiled]) continue
                        if (showDataList[i].children === undefined) showDataList[i].children = []
                        dataList[j].dataLevelCode = showDataList[i].dataLevelCode + '.' + dataList[j][this.dataCodeFiled]
                        showDataList[i].children.push(dataList[j])
                        dataList.splice(j, 1)
                        jLength--
                        j--
                    }
                    if (showDataList[i].children !== undefined) this.linearDataListTreeRecursion(showDataList[i].children, dataList)
                }
            },
            // 回显
            echoCheckDataList() {
                this.input = ''
                this.search('')
                if (this.showDataList.length === 0) return
                this.showCheckDataList.length = 0
                this.checkDataList.length = 0
                for (let i = 0, length = this.echoCheckDataCodeList.length; i < length; i++) {
                    const index = this.dataList.findIndex(data => {
                        return data[this.dataCodeFiled] === this.echoCheckDataCodeList[i]
                    })
                    if (index === -1) continue
                    if (this.checkIfExcludeCode(this.dataList[index])) continue
                    this.checkDataList.push(this.dataList[index])
                }
                this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
                this.showCheckDataList = this.$refs[this.treeRef] && this.$refs[this.treeRef].getCheckedNodes(this.ifJustLeaf)
                this.input = this.checkDataList.map(checkData => checkData[this.showNameFiled]).join('、')
                this.$nextTick(()=>this.search(''))
                this.$emit('callback', this.showDataList)
            },
            // 树形节点改变事件
            treeCheckChange(value, ifCheck) {
                if (ifCheck === false) {
                    value[this.dataNameFiled] = value.label
                    value[this.showNameFiled] = value[this.dataNameFiled]
                }
                this.showCheckDataList.length = 0
                const showCheckDataList = this.$refs[this.treeRef] && this.$refs[this.treeRef].getCheckedNodes(this.ifJustLeaf)
                for (let i = 0, length = showCheckDataList.length; i < length; i++) {
                    if (this.$zwxBase.verifyIsNotBlank(this.codeNameMap)){
                        let name = this.codeNameMap[showCheckDataList[i][this.dataCodeFiled]]
                        if (this.$zwxBase.verifyIsNotBlank(name)){
                            showCheckDataList[i][this.showNameFiled] = name
                        }
                    }
                    this.showCheckDataList.push(showCheckDataList[i])
                }
                if (this.$zwxBase.verifyIsNotBlank(this.codeNameMap)){
                    this.codeNameMap = null
                }
                this.checkDataList.length = 0
                this.input = ''
                for (let i = 0, length = this.showCheckDataList.length; i < length; i++) {
                    this.checkDataList.push(this.showCheckDataList[i])
                }
                this.input = this.checkDataList.map(checkData => checkData[this.showNameFiled]).join('、')
                this.$forceUpdate()
                let data = {
                    hazardNames:this.input,
                    checkDataList:this.checkDataList
                }
                this.$emit('change',data)
            },
            // 树形节点过滤
            filterNodeMethod(query, data) {
                if (this.checkIfExcludeCode(data)) return false
                if (query === undefined || query === null || query === '') return true
                return (data[this.dataPinyinCodeFiled] && data[this.dataPinyinCodeFiled].toUpperCase().indexOf(query.toUpperCase()) !== -1) || data[this.dataNameFiled].indexOf(query) !== -1 || (this.dataPyxNameFiled !== undefined && this.dataPyxNameFiled !== null && this.dataPyxNameFiled !== '' && data[this.dataPyxNameFiled].toUpperCase().indexOf(query.toUpperCase()) !== -1)
            },
            // popover显示
            popoverShow() {
                this.$refs[this.searchRef] && this.$refs[this.searchRef].focus()
            },
            // popover隐藏
            popoverHide() {
                this.search('')
                this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
                this.$refs[this.treeScrollbarRef] && this.$refs[this.treeScrollbarRef].scrollTop(-337)
                this.$refs[this.checkScrollbarRef] && this.$refs[this.checkScrollbarRef].scrollTop(-307)
            },
            // 搜索
            search(searchInput) {
                this.searchInput = searchInput
                this.$refs[this.treeRef] && this.$refs[this.treeRef].filter(this.searchInput)
                this.$refs[this.treeScrollbarRef] && this.$refs[this.treeScrollbarRef].scrollTop(-337)
            },
            // 删除选中项
            showCheckDataListDelete(index) {
                if (this.showCheckDataList.length <= index) return
                this.showCheckDataList.splice(index, 1)
                this.checkDataList.splice(index, 1)
                this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.showCheckDataList)
                this.input = ''
                this.input = this.checkDataList.map(checkData => checkData[this.showNameFiled]).join('、')
                let data = {
                    hazardNames:this.input,
                    checkDataList:this.checkDataList
                }
                this.$emit('change',data)
            },
            // 清空内容
            iconClick(e) {
                if (!(!this.disabled && this.clearable && this.input)) return
                this.input = ''
                this.checkDataList.length = 0
                this.search('')
                this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes([])
                e.stopPropagation()
            },
            // 判断是否是排除项
            checkIfExcludeCode(data) {
                if (this.excludeCodeList.indexOf(data[this.dataCodeFiled]) !== -1) return true
                if (data.dataLevelCode.indexOf('.') !== -1) {
                    const index = this.excludeCodeList.findIndex(excludeCode => {
                        return data.dataLevelCode.indexOf(excludeCode + '.') === 0 || data.dataLevelCode.indexOf('.' + excludeCode + '.') !== -1 || data.dataLevelCode.indexOf('.' + excludeCode) === data.dataLevelCode.length - ('.' + excludeCode).length
                    })
                    if (index !== -1) return true
                }
                return false
            },
            // 设置线形数据集合
            setLinearDataList(linearDataList) {
                if (linearDataList === undefined || linearDataList === null || linearDataList === '') return
                // 深拷贝集合，不动外部的集合
                const linearDataCopyList = JSON.parse(JSON.stringify(linearDataList))
                this.dataList.length = 0
                const dataList = []
                for (let i = 0, length = linearDataCopyList.length; i < length; i++) {
                    linearDataCopyList[i].label = linearDataCopyList[i][this.dataNameFiled]
                    linearDataCopyList[i][this.showNameFiled] = linearDataCopyList[i][this.dataNameFiled]
                    linearDataCopyList[i].dataLevelCode = linearDataCopyList[i][this.dataCodeFiled]
                    this.dataList.push(linearDataCopyList[i])
                    dataList.push(linearDataCopyList[i])
                }
                // 线形转树形第一级
                this.linearDataListTreeFisrt(dataList)
                // 线形转树形递归
                this.linearDataListTreeRecursion(this.showDataList, dataList)
                // 清零临时数据
                dataList.length = 0
                // 回显
                this.echoCheckDataList()
            },
            // 设置过滤编码集合
            setExcludeCodeList(excludeCodeList) {
                this.excludeCodeList.length = 0
                for (let i = 0, length = excludeCodeList.length; i < length; i++) {
                    this.excludeCodeList.push(excludeCodeList[i])
                }
                this.input = ''
                this.search('')
                if (this.showDataList.length === 0) return
                for (let i = 0, length = this.checkDataList.length; i < length; i++) {
                    if (!this.checkIfExcludeCode(this.checkDataList[i])) continue
                    this.checkDataList.splice(i, 1)
                    length--
                    i--
                }
                this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
                this.input = this.checkDataList.map(checkData => checkData[this.dataNameFiled]).join('、')
            },
            // 设置集合回显
            setEchoCheckDataCodeList(echoCheckDataCodeList, map) {
                this.codeNameMap = map
                this.echoCheckDataCodeList.length = 0
                for (let i = 0, length = echoCheckDataCodeList.length; i < length; i++) {
                    this.echoCheckDataCodeList.push(echoCheckDataCodeList[i])
                }
                // 回显
                this.echoCheckDataList()
            },
            // 获取选中的集合
            getCheckDataList() {
                const checkDataList = JSON.parse(JSON.stringify(this.checkDataList))
                for (let i = 0, length = checkDataList.length; i < length; i++) {
                    delete checkDataList[i].label
                    delete checkDataList[i].dataLevelCode
                }
                return checkDataList
            },
            changeHzardName(val, index) {
                if (this.ifEdit) {
                    val.ifModify = true
                    if (this.$zwxBase.verifyIsBlank(this.editCode)) {
                        this.editCode = val.codeNo
                        let obj = this.$refs[val[this.dataCodeFiled]+index]
                        this.$nextTick(()=>{
                            if (obj[0] != undefined) {
                                obj[0].focus()
                            }
                        })
                    }
                }
            },
            focusHazardInput(item) {
                if (this.$zwxBase.verifyIsBlank(item[this.showNameFiled])){
                    item[this.showNameFiled] = item[this.dataNameFiled]
                }
                this.editCode = ''
                this.checkDataList.length = 0
                this.input = ''
                for (let i = 0, length = this.showCheckDataList.length; i < length; i++) {
                    this.checkDataList.push(this.showCheckDataList[i])
                }
                this.input = this.checkDataList.map(checkData => checkData[this.showNameFiled]).join('、')
                let data = {
                    hazardNames:this.input,
                    checkDataList:this.checkDataList
                }
                this.$emit('change',data)
            },
        },
        beforeDestroy() {
            this.input = ''
            this.searchInput = ''
            this.dataList = []
            this.showDataList = []
            this.checkDataList = []
            this.showCheckDataList = []
            this.echoCheckDataCodeList = []
            this.excludeCodeList = []
            this.codeNameMap = null
            this.editCode = ''
        },
    }
</script>

<style lang="less" scoped>
    .hazard-factor-multiple-popover {
        .ms-popover-select-search {
            width: 100% !important;
            padding: 8px 12px 8px 12px !important;

            .zwx-input {
                width: 100% !important;
            }

            /deep/ .el-input__suffix {
                width: 14px !important;
                height: 32px !important;
                line-height: 32px !important;
                margin-right: 5px;
            }
        }

        .ms-popover-select-content {
            height: 338px !important;
            margin-top: 7px !important;
            display: flex !important;

            &-left {
                width: 0 !important;
                height: 100% !important;
                padding-top: 1px !important;
                flex: 325 !important;
                background: #ffffff !important;
                box-shadow: 0px 1px 0px 0px #c6cfde inset !important;

                .ms-tree {
                    margin: 8px 14px 0 14px !important;
                }
            }

            &-right {
                width: 0 !important;
                height: 100% !important;
                flex: 201 !important;
                background: #ffffff !important;
                box-shadow: 1px 1px 0px 0px #c6cfde inset !important;

                .ms-popover-select-content-right-check {
                    height: 38px !important;
                    line-height: 38px !important;
                    box-shadow: 0px -1px 0px 0px #d3dce6 inset !important;
                }

                &-check {
                    width: calc(100% - 1px) !important;
                    height: 30px !important;
                    line-height: 30px !important;
                    padding-left: 12px !important;
                    margin: 1px 0 0 1px !important;
                    font-size: 12px !important;
                    font-family: PingFangSC, PingFangSC-Regular !important;
                    font-weight: 400 !important;
                    color: #000000 !important;
                    background: #ffffff !important;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px #d3dce6 inset !important;
                }

                &-item {
                    width: 100% !important;
                    height: 40px !important;
                    padding-left: 9px !important;
                    padding-right: 25px !important;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;

                    & > div {
                        line-height: 12px !important;
                        font-size: 12px !important;
                        font-family: PingFangSC, PingFangSC-Regular !important;
                        font-weight: 400 !important;
                        white-space: nowrap !important;
                        text-overflow: ellipsis !important;
                        overflow: hidden !important;
                        color: #202937 !important;
                        text-align: end;
                    }
                }
            }
        }

        .ms-popover-select-footer {
            position: relative !important;
            width: 100% !important;
            height: 44px !important;
            padding-right: 12px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: right !important;
            background: #ffffff !important;
            border-top: 1px solid #d3dce6;
        }
    }


    /deep/ .el-tree {
        color: #000000;
        line-height: 38px !important;
        // 将每一行的设置为相对定位 方便后面before after 使用绝对定位来固定位置
        // overflow: auto; //注释这个  可以清除弹框的 树 的 纵向滚动条
        .el-tree-node {
            position: relative;
            padding-left: 0px;
        }
        // 子集像右偏移 给数线留出距离
        .el-tree-node__children {
            padding-left: 42px;
            color: #606266;
            line-height: 30px !important;
        }
        //这是竖线
        .el-tree-node :last-child:before {
            height: 40px;
        }
        .el-tree > .el-tree-node:before {
            border-left: none;
        }
        .el-tree > .el-tree-node:after {
            border-top: none;
        }
        //这自定义的线 的公共部分
        .el-tree-node:before,
        .el-tree-node:after {
            content: '';
            left: -4px;
            position: absolute;
            right: auto;
            border-width: 1px;
        }
        .tree :first-child .el-tree-node:before {
            border-left: none;
        }
        // 竖线
        .el-tree-node:before {
            border-left: 1px solid #b3b7cc;
            bottom: 0px;
            height: 100%;
            top: -25px;
            width: 1px;
        }
        //横线
        .el-tree-node:after {
            border-top: 1px solid #b3b7cc;
            height: 20px;
            top: 14px;
            width: 6px;
        }
        .el-tree-node__expand-icon.is-leaf {
            width: 10px;
        }
        //每一行的高度
        .el-tree-node__content {
            // line-height: 30px;
            height: 30px;
            padding-left: 5px !important;
        }
        .el-tree-node__content:hover {
            background-color: unset !important;
            color: blue;
        }
        .el-tree-node:focus > .el-tree-node__content {
            background-color: #fff !important; //背景色
            color: blue !important;
        }

        .table-item-add-icon {
            float: right;
            justify-content: flex-end;
        }
    }
    //去掉最上级的before  after 即是去电最上层的连接线
    /deep/ .el-tree > div {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
</style>

<style lang="less">
    .hazard-factor-multiple-popover {
        padding: unset !important;
    }
</style>
