<template>
  <div style="padding-bottom: 10px;border-top:1px solid #e1e5f4">
    <base-headline title="商户信息" class="no-top no-bottom"></base-headline>
    <el-form-item style="width:100%" label="" label-width="0" prop="agencyPayBindExpList">
      <el-table class="zwx-table" :data="configList" border stripe tooltip-effect="light">
        <el-table-column prop="index" label="序号" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" label="类型" width="200" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="`agencyPayBindExpList[${scope.$index}].businessType`" :rules="rules.businessType">
              <span class="form-item-value">{{ { '04': '微信', '01': '支付宝', '05': '银联商务' }[scope.row.businessType] || '-' }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="payConfigUid" label="商户标题" min-width="400" header-align="left" align="left">
          <template slot-scope="scope">
            <el-form-item :prop="`agencyPayBindExpList[${scope.$index}].payConfigUid`" :rules="rules.payConfigUid">
              <span class="form-item-value">{{ scope.row.payDescribe || '-' }}</span>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'AppConfigTable',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      // 校验规则
      rules: {
        businessType: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        // payConfigUid: [{ required: true, message: '请选择商户', trigger: ['change', 'blur'] }],
      },
      configList: [],
    }
  },
  computed: {
    configMap() {
      return {
        '04': (this.config.pay_04 || []).filter(v => v.appScenario == 1),
        '01': (this.config.pay_01 || []).filter(v => v.appScenario == 1),
        '05': (this.config.pay_05 || []).filter(v => v.appScenario == 1),
      }
    },
  },
  watch: {
    value: {
      handler(v) {
        this.configList = v
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    addInfo() {
      this.configList.push({
        businessType: undefined,
        payConfigUid: undefined,
      })
      this.$emit('change', this.configList)
    },
    changeType(row) {
      row.payConfigUid = undefined
    },
    deleteInfo(index) {
      this.configList.splice(index, 1)
      this.$emit('change', this.configList)
    },
  },
}
</script>
