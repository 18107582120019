<template>
  <el-form class="zwx-form edit-form" ref="msForm" :model="msForm" label-position="right" label-width="72px">
    <base-headline title="基本信息" style="margin-bottom:7px;" class="no-top "></base-headline>
    <div class="edit-row">
      <el-form-item label="行政区划：" prop="busZoneCode">
        <span class="form-item-value">{{ msForm.busZoneAreaExp?.fullName?.replaceAll('_', '/') || '-' }}</span>
      </el-form-item>
      <el-form-item label="机构名称：" prop="unitName">
        <span class="form-item-value">{{ msForm.unitName || '-' }}</span>
      </el-form-item>
      <el-form-item label-width="98px" label="社会信用代码：" prop="creditCode">
        <span class="form-item-value">{{ msForm.creditCode || '-' }}</span>
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="机构编码：" prop="unitCode">
        <span class="form-item-value">{{ msForm.unitCode || '-' }}</span>
      </el-form-item>
      <el-form-item label="注册日期：" prop="registerDate">
        <span class="form-item-value">{{ msForm.registerDate || '-' }}</span>
      </el-form-item>
      <el-form-item label-width="98px" label="法定代表人：" prop="legalPerson">
        <span class="form-item-value">{{ msForm.legalPerson || '-' }}</span>
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="联系人：" prop="linkPerson">
        <span class="form-item-value">{{ msForm.linkPerson || '-' }}</span>
      </el-form-item>
      <el-form-item label="联系电话：" prop="unitContact">
        <span class="form-item-value">{{ msForm.desensitiUnitContact || '-' }}</span>
      </el-form-item>
      <el-form-item label-width="98px" label="培训机构公章：" prop="annexList">
        <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
          <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, 0)">查看</el-button>
        </div>
        <span v-else>-</span>
      </el-form-item>
    </div>
    <div class="edit-row" style="display: flex;align-items:center;width: 60%;margin: 9px 2px;color: #3A485E !important;">
      <!-- <el-form-item label="管辖范围：" prop="areaAndTrainingAgencyRelationExpList">
        <span v-if="!msForm.areaAndTrainingAgencyRelationExpList.length">-</span>
        <span>{{ msForm.areaAndTrainingAgencyRelationExpList.map(v => v.busZoneCodeName).join('，') }}</span>
      </el-form-item> -->
      <div style="line-height:18px;align-self:flex-start;min-width:70px;">管辖范围：</div>
      <div style="line-height:18px" v-if="!msForm.areaAndTrainingAgencyRelationExpList.length">-</div>
      <div style="line-height:18px">{{ msForm.areaAndTrainingAgencyRelationExpList.map(v => v.busZoneCodeName).join('，') }}</div>
    </div>
    <div class="edit-row" style="padding-bottom: 7px !important;">
      <el-form-item label="机构地址：" prop="busZoneCodeAddr">
        <span class="form-item-value">{{ msForm.busZoneCodeAddr || '-' }}</span>
      </el-form-item>
      <el-form-item v-if="ifTrainingExamSplit" label="营业时间：" prop="tradeTime">
        <span class="form-item-value">{{ msForm.tradeTime || '-' }}</span>
      </el-form-item>
      <el-form-item v-if="ifTrainingExamSplit" label="考试地址：" prop="examAddr">
        <span class="form-item-value">{{ msForm.examAddr || '-' }}</span>
      </el-form-item>
    </div>

    <base-headline title="管理员" style="margin-bottom:7px;"></base-headline>
    <div class="edit-row" style="padding-bottom: 7px !important;">
      <el-form-item label="姓名：" prop="adminUserInfoExp.userName">
        <span class="form-item-value">{{ msForm.adminUserInfoExp.userName || '-' }}</span>
      </el-form-item>
      <el-form-item label="手机号：" prop="adminUserInfoExp.mobileTel">
        <span class="form-item-value">{{ msForm.adminUserInfoExp.mobileTel || '-' }}</span>
      </el-form-item>
      <el-form-item label="账号：" prop="adminUserInfoExp.userNo">
        <span class="form-item-value">{{ msForm.adminUserInfoExp.userNo || '-' }}</span>
      </el-form-item>
    </div>
    <!-- <div class="edit-row">
      <el-form-item label="账号：" prop="adminUserInfoExp.userNo">
        <span class="form-item-value">{{ msForm.adminUserInfoExp.userNo || '-' }}</span>
      </el-form-item>
      
      <el-form-item label="密码：" prop="adminUserInfoExp.password">
        <el-button v-if="$route.params.type == 'edit'" class="zwx-button zwx-button-32" type="primary" @click="resetPassword">初始化密码</el-button>
        <el-input v-else class="zwx-input" v-model="msForm.adminUserInfoExp.password" placeholder="请输入" :maxlength="50" />
      </el-form-item>
    </div> -->

    <app-config-table ref="appConfigTable" v-model="msForm.agencyPayBindExpList" :config="config"></app-config-table>

    <invoice-config-table ref="invoiceConfigTable" v-model="msForm.agencyInvoiceBindExpList" :config="invoiceConfig"></invoice-config-table>

    <!--
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit()">保存</el-button>
    </fixed-footer>
    -->
  </el-form>
</template>

<script>
import AppConfigTable from './AppConfigTable.vue'
import InvoiceConfigTable from './InvoiceConfigTable.vue'

function fetchDetail(uuid) {
  let data = {
    uuid,
  }
  this.$emit('loading', true)
  this.$system.get(
    this.api + '/training/platform/getTrainingAgency-1',
    data,
    true,
    true,
    data => {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.areaAndTrainingAgencyRelationExpList = data.trainingAgency.areaAndTrainingAgencyRelationExpList.map(i => ({ busZoneCode: i.busZoneCode, busZoneCodeName: i.busZoneCodeName }))
        data.trainingAgency.unitContact = this.$zwxSm.sm4JsDecrypt(data.trainingAgency.unitContact)
        data.trainingAgency.adminUserInfoExp.mobileTel = this.$zwxSm.sm4JsDecrypt(data.trainingAgency.adminUserInfoExp.mobileTel)
        data.trainingAgency.annexList = []
        if (this.$zwxBase.verifyIsNotBlank(data.trainingAgency.agencySign)) {
          data.trainingAgency.annexList.push({
            fileIconShow: 'ms-file-jpg-icon',
            check: false,
            filePath: data.trainingAgency.agencySign,
          })
        }

        this.msForm = data.trainingAgency

        this.initPassWord = data.initPassWord
        this.config = data.agencyPayConfigMap
        this.invoiceConfig = data.agencyInvoiceConfigMap
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    () => {
      this.$emit('loading', false)
      this.$system.notify('错误', '网络连接失败', 'error')
    }
  )
}

export default {
  components: {
    AppConfigTable,
    InvoiceConfigTable,
  },
  data() {
    return {
      api: this.$store.state.api,
      rules: {
        busZoneCode: [{ required: true, message: '请选择行政区划', trigger: ['change', 'blur'] }],
        unitName: [{ required: true, message: '请输入机构名称', trigger: ['change', 'blur'] }],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.creditCode, trigger: ['change', 'blur'] },
        ],
        unitCode: [{ required: true, message: '请输入机构编码', trigger: ['change', 'blur'] }],
        registerDate: [{ required: true, message: '请选择注册日期', trigger: ['change', 'blur'] }],
        legalPerson: [{ required: true, message: '请输入法定代表人', trigger: ['change', 'blur'] }],
        linkPerson: [{ required: true, message: '请输入联系人', trigger: ['change', 'blur'] }],
        unitContact: [
          { required: true, message: '请输入联系电话', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.mbPeLdline, trigger: ['change', 'blur'] },
        ],
        areaAndTrainingAgencyRelationExpList: [{ required: true, message: '请选择管辖区域', trigger: ['change', 'blur'] }],
        busZoneCodeAddr: [{ required: true, message: '请输入机构地址', trigger: ['change', 'blur'] }],
        tradeTime: [{ required: true, message: '请选择营业时间', trigger: ['change', 'blur'] }],
        examAddr: [{ required: true, message: '请输入考试地址', trigger: ['change', 'blur'] }],
        adminUserInfoExp: {
          userName: [{ required: true, message: '请输入姓名', trigger: ['change', 'blur'] }],
          mobileTel: [
            { required: true, message: '请输入手机号', trigger: ['change', 'blur'] },
            { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
          ],
          userNo: [{ required: true, message: '请输入账号', trigger: ['change', 'blur'] }],
          password: [{ required: true, message: '请输入密码', trigger: ['change', 'blur'] }],
        },
        agencyPayBindExpList: [
          {
            // required: true,
            validator: (rule, value, callback) => {
              // if (!value.length) {
              //   return callback(new Error('请添加商户信息'))
              // }
              // if (value.length > new Set(value.map(i => i.businessType)).size) {
              //   return callback(new Error('商户类型不能重复'))
              // }
              callback()
            },
            trigger: ['change', 'blur'],
          },
        ],
      },
      zoneCode12From: '',
      areaList: [],
      areaShowList: [],
      areaAndTrainingAgencyRelationExpList: [],
      config: {},
      invoiceConfig: {},
      initPassWord: '',
      msForm: {
        busZoneCode: '',
        unitName: '',
        creditCode: '',

        unitCode: '',
        registerDate: '',
        legalPerson: '',

        linkPerson: '',
        unitContact: '',
        areaAndTrainingAgencyRelationExpList: [],

        busZoneCodeAddr: '',
        tradeTime: undefined,
        examAddr: '',

        adminUserInfoExp: {
          userName: '',
          mobileTel: '',
          userNo: '',
          password: '',
        },

        agencyPayBindExpList: [],
        agencyInvoiceBindExpList: [],
      },
    }
  },
  computed: {
    ifTrainingExamSplit() {
      return window.top.vm.$store.state.config.ifTrainingExamSplit
    },
  },
  mounted() {
    // this.search(1)
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    // 获取地区码表
    this.$simpleCodeTools.getAreaList(this.zoneCode12From, 0, 3, data => {
      this.areaList = data
      let area = this.$simpleCodeTools.baseGetAreaSelect(data, 'zoneCode12')
      this.areaShowList = area.resultAreaList
    })
    this.$nextTick(() => {
      this.$forceUpdate()
    })
    fetchDetail.call(this, this.$route.params.uuid)
  },
  methods: {
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    areaIdLoadOver() { },
    bizZoneChange() {
      this.msForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    themeActivityZoneChange(v, value) {
      let checkedNodeList = this.$refs[value].getCheckedNodes()
      // checkedNodeList = checkedNodeList.filter(item => !(item.parent && item.parent.checked))
      checkedNodeList = checkedNodeList.filter(item => item.parent)
      this[value] = checkedNodeList
      let selectArr = []
      if (this.$zwxBase.verifyIsNotBlank(checkedNodeList)) {
        for (let i in checkedNodeList) {
          let data = checkedNodeList[i].data
          selectArr.push({ busZoneCode: data.value, busZoneCodeName: data.label })
        }
      }
      // this.$set(this.msForm, '_areaAndTrainingAgencyRelationExpList', selectArr)
      this.areaAndTrainingAgencyRelationExpList = selectArr
    },
    resetPassword() {
      this.msForm.adminUserInfoExp.password = this.initPassWord
      this.$system.notify('成功', '初始化密码成功', 'success')
    },
    submit() {
      this.$refs.msForm.validate(valid => {
        if (!valid) return
        const params = {
          ...this.msForm,
          ifTrainingExamSplit: this.ifTrainingExamSplit,
          unitContact: this.$zwxSm.sm4JsEncrypt(this.msForm.unitContact),
          areaAndTrainingAgencyRelationExpList: this.areaAndTrainingAgencyRelationExpList,
          tradeTime: this.msForm.tradeTime?.join('-'),
          adminUserInfoExp: {
            ...this.msForm.adminUserInfoExp,
            mobileTel: this.$zwxSm.sm4JsEncrypt(this.msForm.adminUserInfoExp.mobileTel),
            password: this.$route.params.type == 'edit' ? this.msForm.adminUserInfoExp.password : this.$zwxSm.md5(this.msForm.adminUserInfoExp.password),
          },
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/training/platform/addOrModifyTrainingAgency-1',
          params,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.$router.push({ name: 'trainingInstitutionManagement', params: { fresh: true } })
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: -2px !important;
}

/deep/ .zwx-input {
  width: 220px !important;
}
/deep/ .zwx-cascader {
  width: 220px !important;
  .el-input__inner {
    color: #191919 !important;
    padding-left: 6px !important;
    border: 1px solid #a8bbcf !important;
    border-radius: 2px !important;
  }
}
/deep/ .zwx-date-picker {
  width: 220px !important;
}
.zwx-time-picker {
  padding: 3px 6px;
  width: 220px;
  border: 1px solid #a8bbcf !important;
  border-radius: 2px !important;
  /deep/ .el-range-separator {
    width: unset;
  }
}
.form-item-value {
  display: inline-block;
  min-width: 220px;
}
</style>
