<template>
  <div style="padding-bottom: 120px;border-top:1px solid #e1e5f4">
    <base-headline title="开票配置" class="no-top no-bottom"></base-headline>
    <el-form-item style="width:100%" label="" label-width="0" prop="agencyInvoiceBindExpList">
      <el-table class="zwx-table" :data="configList" border stripe tooltip-effect="light">
        <el-table-column prop="index" label="序号" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" label="类型" width="200" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="`agencyInvoiceBindExpList[${scope.$index}].businessType`" :rules="rules.businessType">
              <span class="form-item-value">{{ { '1': '用友开票' }[scope.row.businessType] || '-' }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceConfigUid" label="配置名称" min-width="400" header-align="left" align="left">
          <template slot-scope="scope">
            <el-form-item :prop="`agencyInvoiceBindExpList[${scope.$index}].invoiceConfigUid`" :rules="rules.invoiceConfigUid">
              <span class="form-item-value">{{ scope.row.invoiceDescribe || '-' }}</span>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'InvoiceConfigTable',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      // 校验规则
      rules: {
        businessType: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        invoiceConfigUid: [{ required: true, message: '请选择配置名称', trigger: ['change', 'blur'] }],
      },
      configList: [],
    }
  },
  computed: {
    configMap() {
      return {
        '1': this.config.invoice_1 || [],
      }
    },
  },
  watch: {
    value: {
      handler(v) {
        this.configList = v
      },
      immediate: true,
    },
  },
  created() { },
  mounted() { },
  methods: {
    addInfo() {
      this.configList.push({
        businessType: undefined,
        invoiceConfigUid: undefined,
      })
      this.$emit('change', this.configList)
    },
    changeType(row) {
      row.invoiceConfigUid = undefined
    },
    deleteInfo(index) {
      this.configList.splice(index, 1)
      this.$emit('change', this.configList)
    },
  },
}
</script>
