<template>
    <div class="unit-info-select">
      <el-popover popper-class="unit-search-popover" placement="bottom-start" v-model="unitNameSearchVisible" :trigger="unitNameSearchVisible ? 'manual' : null">
        <el-main class="unit-search-content">
          <zwx-scrollbar-y style="height: 100%" ref="searchScrollbar">
            <div v-for="(item, index) in searchUnitList" :key="index">
              <div class="drop-down-item" @click="unitNameSelect(item)">
                <div class="unit-name">
                  {{ item.unitName }}
                </div>
                <div>
                  <span class="label">法人：</span>
                  <span>{{ item.legalPerson }}</span>
                  <div class="separator" />
                  <span class="label">社会信用代码：</span>
                  <span>{{ item.creditCode }}</span>
                </div>
              </div>
            </div>
            <div class="drop-down-item item-more" style="padding-left: 12px;" @click="openUnitNameSearchDialog(unitName)" v-if="ifShowMoreBtn">
              更多选择
            </div>
          </zwx-scrollbar-y>
        </el-main>
        <el-input class="zwx-input ms-unit-input" :style="`width:${width}!important`" :disabled="unitNameDisabled" :placeholder="placeholderText" v-model.trim="unitName" slot="reference" maxlength="100">
          <el-link :underline="false" style="margin-right: 5px !important;margin-top: 10px;" slot="suffix" type="primary" :icon="searchOrDelIcon" v-if="opType === 'add' && ifIconShow" @click="unitNameSearchOrDel(unitName)"></el-link>
        </el-input>
      </el-popover>
      <!--客户信息搜索弹出窗-->
      <base-dialog class="ms-unit-search-dialog" ref="msUnitSearchDialog" title="单位信息" :pagination="true" @determine="unitSearchDialogDetermine()" :parentPage="unitSearchDialog.msUnitSearchDialogForm.currentPage" :pageSize="unitSearchDialog.pageSize" :total="unitSearchDialog.total" @currentPage="unitInfoDialogSearchInquire" @cancel="cancelUnitSearchDialog">
        <el-form class="zwx-form" key="msUnitSearchDialogForm" ref="msUnitSearchDialogForm" :model="unitSearchDialog.msUnitSearchDialogForm" label-position="right" @submit.native.prevent>
          <div>
            <el-form-item label="单位名称："  label-width="70px">
              <el-input class="zwx-input" style="width:200px" v-model.trim="unitSearchDialog.msUnitSearchDialogForm.unitName" clearable></el-input>
            </el-form-item>
            <el-form-item label="社会信用代码："  label-width="98px">
              <el-input class="zwx-input" style="width:200px" v-model.trim="unitSearchDialog.msUnitSearchDialogForm.creditCode" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="unitInfoDialogSearch">查询</el-button>
            </el-form-item>
          </div>
        </el-form>
        <div style="width: 100%">
          <el-table class="zwx-table" key="rid" :data="unitSearchDialog.tablePageList" tooltip-effect="light" highlight-current-row stripe border>
            <el-table-column label="选择" width="80" header-align="center" align="center">
              <template slot-scope="scope">
                <el-radio class="zwx-radio" v-model="unitSearchDialog.unitinfo" @change="changeunitInfo" :label="scope.row" style="margin-right: unset !important;width: 15px;"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="unitName" label="单位名称" min-width="200" header-align="center" align="left" show-overflow-tooltip :resizable="false"></el-table-column>
            <el-table-column prop="creditCode" label="社会信用代码" min-width="140" header-align="center" align="center" show-overflow-tooltip :resizable="false"></el-table-column>
            <el-table-column prop="legalPerson" label="法人" min-width="140" header-align="center" align="center" show-overflow-tooltip :resizable="false"></el-table-column>
          </el-table>
        </div>
      </base-dialog>
    </div>
  </template>
  <script>
  export default {
    name: 'UnitSelect',
    model: {
      prop: 'value',
      event: 'propEvent',
    },
    props: {
      value: { String, default: '' },
      requestUrl: { String, default: '' },
      disabled: { Boolean },
      opType: { String, default: 'add' },
      searchUnitName: { String, default: 'unitName' },
      width: { type: String, default: '300px' },
      ifIconShow: { Boolean, default: true },
      ifAuthority: { Boolean, default: true },
      placeholderText: { String, default: '' },
      ifShowMoreBtn: { Boolean, default: true },
      ifNeedMore: { Boolean, default: true },
      isSms4:{Boolean,default: false},
      isPost:{Boolean,default: false},
      otherRequestUrl:{String, default: null },
      deleteTips: { String, default: '删除单位名称将清空页面信息,是否确定？' },
      ifNeedFive: { Boolean, default: true },//是否需要5个字符以上默认为true
      ifShowConfirmDialog: { Boolean, default: true },// 点击删除时是否需要弹框提示确认   默认为true
    },
    data() {
      return {
        api: this.$store.state.api,
        loading: false,
        unitName: this.value,
        creditCode: '',
        unitNameDisabled: this.disabled,
        unitNameSearchVisible: false,
        searchOrDelIcon: 'el-icon-search',
        // 单位搜索弹框
        unitSearchDialog: {
          tableList: [],
          tablePageList: [],
          pageSize: 5,
          total: 0,
          unitinfo: null,
          msUnitSearchDialogForm: {
            currentPage: 1,
            unitName: '',
            creditCode: '',
          },
        },
        searchUnitList: [],
      }
    },
    created() { },
    watch: {
      unitName(val) {
        this.$emit('propEvent', val)
      },
      value: {
        handler(v) {
          this.unitName = v
        },
        immediate: true,
        deep: true,
      },
      creditCode: {
        handler() {
          if (this.opType === 'add') {
            if (this.$zwxBase.verifyIsNotBlank(this.creditCode)) {
              this.searchOrDelIcon = 'el-icon-delete'
              this.unitNameDisabled = true
            } else {
              this.searchOrDelIcon = 'el-icon-search'
              this.unitNameDisabled = false
            }
          } else {
            return true
          }
        },
      },
    },
    computed: {},
    mounted() { },
    methods: {
        changeunitInfo(){
            console.log(this.unitSearchDialog.unitinfo)
        },
      /**
       * 单位信息搜索或删除
       */
      unitNameSearchOrDel(unitNameLike) {
        if (this.searchOrDelIcon === 'el-icon-search') {
          if (this.ifNeedFive && unitNameLike.length < 5) {
            return
          }
          this.searchUnitList = []
          // 查询企业信息
          let data = {}
          this.$set(data, this.searchUnitName, this.isSms4?this.$zwxSm.sm4JsEncrypt(this.unitName):this.unitName)
          this.$emit('loading', true)
          if(this.isPost){
            this.$system.post(
            this.api + this.requestUrl,
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.searchUnitList = data.list
                if (this.ifNeedMore) {
                  this.unitNameSearchVisible = true
                } else {
                  if (this.searchUnitList.length > 0) {
                    this.unitNameSearchVisible = true
                  } else {
                    this.$emit('unitNameSearch')
                  }
                }
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
          }else{
            this.$system.get(
            this.api + this.requestUrl,
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.searchUnitList = data.list
                if (this.ifNeedMore) {
                  this.unitNameSearchVisible = true
                } else {
                  if (this.searchUnitList.length > 0) {
                    this.unitNameSearchVisible = true
                  } else {
                    this.$emit('unitNameSearch')
                  }
                }
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
          }
          
        
        } else {
          if (this.ifShowConfirmDialog) {
            if(this.isPost){
                this.creditCode = ''
                this.$emit('unitNameSearchOrDel', 'del')
            }else{
                  // 删除页面企业信息
                this.$system.msgbox('', '提示', '删除单位名称将清空页面信息,是否确定？', '确定', '取消', () => {
                this.creditCode = ''
                this.$emit('unitNameSearchOrDel', 'del')
                })
            }
          
          } else {
            // 删除页面企业信息
            this.creditCode = ''
            this.$emit('unitNameSearchOrDel', 'del')
          }
        }
      },
      /**
       * 客户信息选择
       */
      unitNameSelect(item) {
        this.creditCode = item.creditCode
        this.unitNameSearchVisible = false
        this.$emit('change', item)
      },
      /**
       * 打开客户信息搜索弹框
       */
      openUnitNameSearchDialog(unitName) {
        this.$refs.msUnitSearchDialog.show(true)
        this.unitSearchDialog.msUnitSearchDialogForm.unitName = unitName
        this.unitInfoDialogSearch()
      },
      /**
       * 单位信息搜索弹框查询
       */
      unitInfoDialogSearch() {
        this.unitSearchDialog.msUnitSearchDialogForm.currentPage = 1
        this.unitSearchDialog.tablePageList=[]
        let unitName = this.unitSearchDialog.msUnitSearchDialogForm.unitName
        let creditCode = this.unitSearchDialog.msUnitSearchDialogForm.creditCode
        if ((unitName == undefined || unitName == null || unitName == '') && (creditCode == undefined || creditCode == null || creditCode == '')) {
          this.$system.notify('警告', '客户名称或社会信用代码不能为空', 'warning')
          return
        }
        if (unitName != undefined && unitName != null && unitName != '' && unitName.length < 5) {
          this.$system.notify('警告', '客户名称至少输入5个字符进行检索', 'warning')
          return
        }
        this.loading = true
        let data = {
          ...this.unitSearchDialog.msUnitSearchDialogForm,
        }
        let mess = ''
        this.unitSearchDialog.unitinfo=null
        if (this.ifAuthority) {
          if(this.otherRequestUrl){
            mess=this.otherRequestUrl
            if(this.isSms4){
              data.unitName=this.$zwxSm.sm4JsEncrypt(data.unitName)
            }
          }else{
            mess = '/zky/employer/getUnitInfoByUnitNameOrCreditCode-1'
          }
        } else {
          mess = '/zky/employer/registerGetUnitInfoByUnitNameOrCreditCode-0'
        }
        this.$emit('loading', true)
        this.$system.get(
          this.api + mess,
          data,
          false,
          false,
          data => {
           
            this.$emit('loading', false)
            if (data.type == '00') {
              this.unitSearchDialog.tableList = data.tableList || data.list
              this.unitSearchDialog.total = data.total || data.list.length
              if (this.$zwxBase.verifyIsNotBlank(this.unitSearchDialog.tableList)) {
                this.unitSearchDialog.tablePageList = this.unitSearchDialog.tableList.slice(0, this.unitSearchDialog.pageSize)
              } else {
                this.unitSearchDialog.tablePageList = []
              }
            } else {
            //   this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.loading = false
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      },
      /**
       * 客户信息搜索弹框分页查询
       */
      unitInfoDialogSearchInquire(currentPage) {
        this.unitSearchDialog.msUnitSearchDialogForm.currentPage = currentPage
        let beginPage = (this.unitSearchDialog.msUnitSearchDialogForm.currentPage - 1) * this.unitSearchDialog.pageSize
        this.unitSearchDialog.tablePageList = this.unitSearchDialog.tableList.slice(beginPage, beginPage + this.unitSearchDialog.pageSize)
      },
      /**
       * 确认弹框
       */
      unitSearchDialogDetermine() {
        if (this.$zwxBase.verifyIsNotBlank(this.unitSearchDialog.unitinfo)) {
          this.creditCode = this.unitSearchDialog.unitinfo.creditCode
          this.$refs.msUnitSearchDialog.show(false)
          this.unitNameSearchVisible = false
          this.$emit('change', this.unitSearchDialog.unitinfo)
        } else {
          this.$system.notify('警告', '请选择一个客户', 'warning')
        }
      },
      /**
       * 取消弹框
       */
      cancelUnitSearchDialog() {
        this.$refs.msUnitSearchDialog.show(false)
      },
    },
  }
  </script>
  
  <style lang="less" scoped>
  .ms-unit-search-dialog {
    /deep/ .el-dialog {
      width: 850px !important;
    }
  }
  </style>
  <style>
  .ms-unit-search-dialog .zwx-radio > .el-radio__input + .el-radio__label {
    display: none;
  }
  </style>
  