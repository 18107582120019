var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hazard-distribution-index" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "hazardDistributionIndexRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            "label-position": "right",
            rules: _vm.rules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "choose-box edit-row",
              staticStyle: {
                "border-bottom": "1px solid rgba(213, 217, 224, 1)"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "有无以下职业病危害因素："
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(1)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsDust,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsDust", $$v)
                        },
                        expression: "msForm.existsDust"
                      }
                    },
                    [_vm._v("粉尘因素")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(2)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsChemical,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsChemical", $$v)
                        },
                        expression: "msForm.existsChemical"
                      }
                    },
                    [_vm._v("化学因素")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(3)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsPhysical,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsPhysical", $$v)
                        },
                        expression: "msForm.existsPhysical"
                      }
                    },
                    [_vm._v("物理因素")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(4)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsRadioactivity,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsRadioactivity", $$v)
                        },
                        expression: "msForm.existsRadioactivity"
                      }
                    },
                    [_vm._v("放射性因素")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(5)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsBiotic,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsBiotic", $$v)
                        },
                        expression: "msForm.existsBiotic"
                      }
                    },
                    [_vm._v("生物因素")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        border: "",
                        size: "mini",
                        disabled: 2 == _vm.declareType && !_vm.firstAdd
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChange(6)
                        }
                      },
                      model: {
                        value: _vm.msForm.existsOther,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsOther", $$v)
                        },
                        expression: "msForm.existsOther"
                      }
                    },
                    [_vm._v("其他因素")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.existsDustFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "粉尘因素接触总人数：",
                          prop: "dustContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.dustContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "dustContacts", $$v)
                            },
                            expression: "msForm.dustContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "1",
                              11,
                              "粉尘因素名称",
                              "添加其他类粉尘",
                              _vm.msForm.dustHazardList,
                              "dustHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.dustHazardList, function(childs, i) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "13px",
                                        margin: "0 3px",
                                        cursor: "pointer"
                                      },
                                      attrs: {
                                        src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteSelectedFactor(
                                            1,
                                            i,
                                            j
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "dustHazardList" + i + j,
                                          prop:
                                            "dustHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "dustHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "dustHazardList" + i + j,
                                          prop:
                                            "dustHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "dustHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "dustHazardList" + i + j,
                                          prop:
                                            "dustHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "dustHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.existsChemicalFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "化学因素接触总人数：",
                          prop: "chemicalContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.chemicalContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "chemicalContacts", $$v)
                            },
                            expression: "msForm.chemicalContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "2",
                              11,
                              "化学因素名称",
                              "添加其他类化学因素",
                              _vm.msForm.chemicalHazardList,
                              "chemicalHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.chemicalHazardList, function(childs, i) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    0 == child.supervisionRequirement &&
                                    (2 != _vm.declareType ||
                                      (2 == _vm.declareType && _vm.firstAdd))
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            margin: "0 3px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSelectedFactor(
                                                2,
                                                i,
                                                j
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "chemicalHazardList" + i + j,
                                          prop:
                                            "chemicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "chemicalHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "chemicalHazardList" + i + j,
                                          prop:
                                            "chemicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "chemicalHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "chemicalHazardList" + i + j,
                                          prop:
                                            "chemicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "chemicalHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.existsPhysicalFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "物理因素接触总人数：",
                          prop: "physicalContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.physicalContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "physicalContacts", $$v)
                            },
                            expression: "msForm.physicalContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "3",
                              11,
                              "物理因素名称",
                              "添加其他类物理因素",
                              _vm.msForm.physicalHazardList,
                              "physicalHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.physicalHazardList, function(childs, i) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    0 == child.supervisionRequirement &&
                                    (2 != _vm.declareType ||
                                      (2 == _vm.declareType && _vm.firstAdd))
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            margin: "0 3px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSelectedFactor(
                                                3,
                                                i,
                                                j
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "physicalHazardList" + i + j,
                                          prop:
                                            "physicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "physicalHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "physicalHazardList" + i + j,
                                          prop:
                                            "physicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "physicalHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "physicalHazardList" + i + j,
                                          prop:
                                            "physicalHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "physicalHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.existsRadioactivityFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "放射危害因素接触总人数：",
                          prop: "radioactivityContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.radioactivityContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "radioactivityContacts", $$v)
                            },
                            expression: "msForm.radioactivityContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "4",
                              11,
                              "放射因素名称",
                              "添加其他类放射因素",
                              _vm.msForm.radioactivityHazardList,
                              "radioactivityHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.radioactivityHazardList, function(
                      childs,
                      i
                    ) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    0 == child.supervisionRequirement &&
                                    (2 != _vm.declareType ||
                                      (2 == _vm.declareType && _vm.firstAdd))
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            margin: "0 3px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSelectedFactor(
                                                4,
                                                i,
                                                j
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "radioactivityHazardList" + i + j,
                                          prop:
                                            "radioactivityHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "radioactivityHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "radioactivityHazardList" + i + j,
                                          prop:
                                            "radioactivityHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "radioactivityHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "radioactivityHazardList" + i + j,
                                          prop:
                                            "radioactivityHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "radioactivityHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.existsBioticFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "生物因素接触总人数：",
                          prop: "bioticContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.bioticContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "bioticContacts", $$v)
                            },
                            expression: "msForm.bioticContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "5",
                              11,
                              "生物因素名称",
                              "添加其他类生物因素",
                              _vm.msForm.bioticHazardList,
                              "bioticHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.bioticHazardList, function(childs, i) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    0 == child.supervisionRequirement &&
                                    (2 != _vm.declareType ||
                                      (2 == _vm.declareType && _vm.firstAdd))
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            margin: "0 3px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSelectedFactor(
                                                5,
                                                i,
                                                j
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "bioticHazardList" + i + j,
                                          prop:
                                            "bioticHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "bioticHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "bioticHazardList" + i + j,
                                          prop:
                                            "bioticHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "bioticHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "bioticHazardList" + i + j,
                                          prop:
                                            "bioticHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "bioticHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.existsOtherFlag
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "185px",
                          label: "其他因素接触总人数：",
                          prop: "otherContacts"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "120px !important" },
                          attrs: {
                            maxlength: "12",
                            min: 0,
                            oninput: "value=value.replace(/[^0-9]/g,'')",
                            placeholder: "请输入",
                            clearable: ""
                          },
                          model: {
                            value: _vm.msForm.otherContacts,
                            callback: function($$v) {
                              _vm.$set(_vm.msForm, "otherContacts", $$v)
                            },
                            expression: "msForm.otherContacts"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-28",
                        attrs: {
                          icon: "el-icon-plus",
                          disabled: 2 == _vm.declareType && !_vm.firstAdd
                        },
                        on: {
                          click: function($event) {
                            return _vm.factorDialogShow(
                              "6",
                              11,
                              "其他因素名称",
                              "添加其他类其他因素",
                              _vm.msForm.otherHazardList,
                              "otherHazardList"
                            )
                          }
                        }
                      },
                      [_vm._v(" 添加 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-table" },
                  [
                    _c("div", { staticClass: "div-table-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      ),
                      _c("div", { staticClass: "div-table-head-border" }),
                      _c(
                        "div",
                        {
                          staticClass: "div-table-head-name-col flew-center",
                          staticStyle: { flex: "1" }
                        },
                        [_vm._v("接触危害因素")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("接触人数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("检测点数")]
                      ),
                      _c(
                        "div",
                        { staticClass: "div-table-head-name-col flew-center" },
                        [_vm._v("超标点数")]
                      )
                    ]),
                    _vm._l(_vm.msForm.otherHazardList, function(childs, i) {
                      return [
                        _c(
                          "div",
                          { staticClass: "div-table-row" },
                          [
                            _vm._l(childs, function(child, j) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-name-col flew-center",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      flex: "1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(child.hazardsName) + " "
                                    ),
                                    0 == child.supervisionRequirement &&
                                    (2 != _vm.declareType ||
                                      (2 == _vm.declareType && _vm.firstAdd))
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "13px",
                                            margin: "0 3px",
                                            cursor: "pointer"
                                          },
                                          attrs: {
                                            src: require("@/assets/images/hazard_declaration/ic_delete.png")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSelectedFactor(
                                                6,
                                                i,
                                                j
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "otherHazardList" + i + j,
                                          prop:
                                            "otherHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".staffNumber",
                                          rules: _vm.rules.staffNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            min: 0,
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "otherHazardList",
                                                i,
                                                j,
                                                "staffNumber"
                                              )
                                          },
                                          model: {
                                            value: child.staffNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "staffNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.staffNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "otherHazardList" + i + j,
                                          prop:
                                            "otherHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".checkNumber",
                                          rules: _vm.rules.checkNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: ""
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "otherHazardList",
                                                i,
                                                j,
                                                "checkNumber"
                                              )
                                          },
                                          model: {
                                            value: child.checkNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "checkNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.checkNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-td-num-col flew-center"
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0 !important",
                                          "margin-right": "0 !important"
                                        },
                                        attrs: {
                                          id: "otherHazardList" + i + j,
                                          prop:
                                            "otherHazardList." +
                                            i +
                                            "." +
                                            j +
                                            ".overNumber",
                                          rules: _vm.rules.overNumber
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          staticStyle: {
                                            width: "100% !important"
                                          },
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "12",
                                            clearable: "",
                                            min: 0
                                          },
                                          on: {
                                            input: value =>
                                              _vm.handleInput(
                                                value,
                                                "otherHazardList",
                                                i,
                                                j,
                                                "overNumber"
                                              )
                                          },
                                          model: {
                                            value: child.overNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                child,
                                                "overNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "child.overNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                j === 0
                                  ? _c("div", {
                                      staticClass: "div-table-head-border"
                                    })
                                  : _vm._e(),
                                childs.length == 1
                                  ? _c("div", {
                                      staticStyle: { width: "calc(50% - 5px)" }
                                    })
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      ),
      _c("demo", {
        directives: [
          { name: "show", rawName: "v-show", value: true, expression: "true" }
        ],
        ref: "hazardSelectDialog",
        attrs: {
          selectedFactors: _vm.factorSelectDialog.factors,
          title: _vm.factorSelectDialog.title,
          tableLabel: _vm.factorSelectDialog.dialogRoeName,
          hazardsSort: _vm.factorSelectDialog.hazardsSort
        },
        on: { submitFactor: _vm.confirmFactorDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }